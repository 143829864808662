import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import StarredLink from '../components/starred-link/starred-link'
import HeroImg from '../assets/images/hero-about.png'

const query = graphql`
    query {
        contentfulAboutPage {
            whoWeAreTitle
            whoWeAreItems {
                title
                description
            }
        }
    }
`

const Content = ({ location }) => {
    const data = useStaticQuery(query)

    return (
        <Layout>
            <Seo
                title="About - South Lane"
                desc="We work with companies at any stage of their business or product lifecycle on projects ranging from branding and content through to design and development."
                pathname={location.pathname}
            />
            <div className="content-narrow content-narrow--small">
                <h1>{data.contentfulAboutPage.whoWeAreTitle}</h1>
            </div>

            <div className="content-hero-image">
                <img src={HeroImg} alt="About" />
            </div>

            <div className="content-narrow">
                <div className="content-blocks-list content-blocks-list--about">
                    {data.contentfulAboutPage.whoWeAreItems.map(item => (
                        <div key={item.title} className="common-content-block">
                            <h6>{item.title}</h6>
                            <div
                                // eslint-disable-next-line
                                dangerouslySetInnerHTML={{
                                    __html: item.description,
                                }}
                            />
                        </div>
                    ))}
                </div>

                <p>
                    <StarredLink text="We're hiring!" link="/careers" />
                </p>
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
