// noinspection ES6CheckImport
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import SVG from 'react-inlinesvg'
import IcoStar from '../../assets/images/ico-star.svg'
import './starred-link.scss'

const StarredLink = ({ text, link }) => {
    const Icon = () => <SVG src={IcoStar} className="svg-ico color-decor-3" />

    return (
        <Link to={link} className="starred-link link-with-ico">
            <Icon />
            <strong>{text}</strong>
        </Link>
    )
}

StarredLink.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}

export default StarredLink
